import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
const TopBar = props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const clickHandler = action => {
    ReactGA.event({
      category: 'NavBar',
      action: action,
    });
  };
  return (
    <Navbar color="light" light expand="md">
      <NavbarToggler onClick={toggle} />
      <div className="navbar-header">
        <Link
          onClick={() => {
            clickHandler('Home Page Clicked');
          }}
          to="/"
          className="navbar-brand"
        >
          <img
            src={require('./sm_new_orange.png')}
            style={{ width: '200px' }}
            alt="Sneaker Plug"
          />
        </Link>
      </div>
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <Link
              onClick={() => {
                clickHandler('Top Deals Clicked');
              }}
              to="/topdeals"
              className="nav-link"
            >
              Top Deals
            </Link>
          </NavItem>
          <NavItem>
            <Link
              onClick={() => {
                clickHandler('Popular Clicked');
              }}
              to="/popular"
              className="nav-link"
            >
              Popular
            </Link>
          </NavItem>
          <NavItem>
            <Link
              onClick={() => {
                clickHandler('New Drops Clicked');
              }}
              to="/newdrops"
              className="nav-link"
            >
              New Drops
            </Link>
          </NavItem>
        </Nav>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <Link
              onClick={() => {
                clickHandler('About Clicked');
              }}
              to="/about"
              className="nav-link"
            >
              About
            </Link>
          </NavItem>
          <NavItem>
            <a
              className="nav-link"
              href="mailto:contact.findyourkicks@gmail.com"
              onClick={() => {
                clickHandler('Contact Us Clicked');
              }}
            >
              Contact Us
            </a>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default TopBar;
