import React, { Component } from 'react';
import Box from './box';
import ceil from 'lodash/ceil';
import Filter from './filter';
import ReactGA from 'react-ga';
import { ArrowLeftIcon, ArrowRightIcon } from '@primer/octicons-react';
import SearchBar from '../components/searchbar';
const MAX_COLS = 4;
const MAX_PAGE = 10;
const topdeals_link = 'https://sneakerplug-v1.herokuapp.com/api/topdeals/db/';
function get_api_url(state) {
  let url_gender = '';
  let url_size = '';
  let url_brand = '';

  if (state.gender) url_gender = '&gender=' + state.gender;

  if (state.size) url_size = '&size=' + state.size;

  if (state.brand) url_brand = '&brand=' + state.brand;

  return (
    topdeals_link +
    state.priceRange +
    '/?pageNumber=' +
    state.currentPage +
    '&pageSize=' +
    state.shoesPerPage +
    url_gender +
    url_size +
    url_brand
  );
}

class TopDeals extends Component {
  state = {
    loading: true,
    currentPage: 1,
    shoesPerPage: 28,
    shoes: [],
    priceRange: 2, // default range
    gender: 'Men',
    size: null,
    brand: null,
    // currentShoes: [],
  };

  componentDidMount() {
    // console.log('Container - Mounted');
    fetch(get_api_url(this.state))
      .then(res => res.json())
      .then(
        result => {
          // let updated = this.state.shoes.concat(result);
          this.setState({
            loading: false,
            shoes: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          this.setState({
            loading: false,
            shoes: [],
          });
        }
      );
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.priceRange !== this.state.priceRange ||
      prevState.gender !== this.state.gender ||
      prevState.size !== this.state.size ||
      prevState.brand !== this.state.brand
    ) {
      this.setState({ loading: true });
      this.componentDidMount();
    }
  }

  prevPage = () => {
    if (this.state.currentPage > 1) {
      ReactGA.event({
        category: 'Supplementary Buttons',
        action: 'Prev Clicked',
        label: 'Top Deals',
      });
      this.setState({ currentPage: this.state.currentPage - 1 });
      this.setPage(this.state.currentPage - 1);
    }
  };

  nextPage = () => {
    if (this.state.currentPage < MAX_PAGE) {
      ReactGA.event({
        category: 'Supplementary Buttons',
        action: 'Next Clicked',
        label: 'Top Deals',
      });
      this.setState({ currentPage: this.state.currentPage + 1 });
      this.setPage(this.state.currentPage + 1);
    }
  };

  setPage = pageNumber => {
    const indexOfLastShoe = pageNumber * this.state.shoesPerPage;
    const indexOfFirstShoe = indexOfLastShoe - this.state.shoesPerPage;
    this.setState({
      currentShoes: this.state.shoes.slice(indexOfFirstShoe, indexOfLastShoe),
    });
  };

  handleFilter = (priceRange, gender, size, brand) => {
    if (typeof size !== 'number' || !size) size = null;
    if (brand === 'All' || !brand) brand = null;
    this.setState({
      priceRange: priceRange,
      gender: gender,
      size: size,
      brand: brand,
      currentPage: 1,
    });
  };
  handleSearch = (query, gender, size, brand, styleId) => {
    // console.log('About: Handling search');
  };

  render() {
    if (this.state.loading === false && !this.state.shoes.length)
      return (
        <React.StrictMode>
          <div className="page-container">
            <h1 className="text-center font-weight-normal mt-5 my-3 mx-4">
              Top Deals
            </h1>
            <p className="text-center text-secondary px-5">
              Check out the best deals from Nike, Goat, Stockx, and other major
              retailers.
            </p>

            <div className="popular container p-0 my-2">
              <div className="container my-0 p-0">
                <Filter buttonLabel={'Filter >'} onFilter={this.handleFilter} />
              </div>
              <div className="d-flex justify-content-center">
                <div
                  hidden={!this.state.loading}
                  className="spinner-border my-5"
                  style={{ width: '4rem', height: '4rem' }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              <SearchBar aboutPage={true} onSearch={this.handleSearch} />
            </div>

            <ul
              className="pagination justify-content-center my-3"
              style={{ margin: '0.2rem' }}
            >
              <li className={this.getPrevBadgeClass()}>
                <button
                  onClick={this.prevPage}
                  className={this.getPrevButtonBadgeClass()}
                >
                  <ArrowLeftIcon size={16} />
                  Prev
                </button>
              </li>
              <li className={this.getNextBadgeClass()}>
                <button
                  onClick={this.nextPage}
                  className={this.getNextButtonBadgeClass()}
                >
                  Next
                  <ArrowRightIcon size={16} />
                </button>
              </li>
            </ul>
          </div>
        </React.StrictMode>
      );
    let num_rows = ceil(this.state.shoes.length / MAX_COLS);
    let html_rows = [];
    for (let i = 0; i < num_rows; i++) {
      let content = [];
      let q = i * MAX_COLS;
      while (q < i * MAX_COLS + MAX_COLS && q < this.state.shoes.length) {
        content.push(
          <Box
            size={null}
            loading={this.state.loading}
            shoe={this.state.shoes[q]}
            key={this.state.shoes[q].id}
          />
        );
        q++;
      }
      html_rows.push(<div className="row row-box p-0">{content}</div>);
    }

    return (
      <React.StrictMode>
        <div className="page-container">
          <h1 className="text-center font-weight-normal mt-5 my-3 mx-4">
            Top Deals
          </h1>
          <p className="text-center text-secondary px-5">
            Check out the best deals from Nike, Goat, Stockx, and other major
            retailers.
          </p>

          <div className="popular container p-0 my-2">
            <div className="container my-0 p-0">
              <Filter buttonLabel={'Filter >'} onFilter={this.handleFilter} />
            </div>
            <div className="d-flex justify-content-center">
              <div
                hidden={!this.state.loading}
                className="spinner-border my-5"
                style={{ width: '4rem', height: '4rem' }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            <div hidden={this.state.loading} className="content container">
              {html_rows}
            </div>
          </div>

          <ul
            className="pagination justify-content-center my-3"
            style={{ margin: '0.2rem' }}
          >
            <li className={this.getPrevBadgeClass()}>
              <button
                onClick={this.prevPage}
                className={this.getPrevButtonBadgeClass()}
              >
                <ArrowLeftIcon size={16} />
                Prev
              </button>
            </li>
            <li className={this.getNextBadgeClass()}>
              <button
                onClick={this.nextPage}
                className={this.getNextButtonBadgeClass()}
              >
                Next
                <ArrowRightIcon size={16} />
              </button>
            </li>
          </ul>
        </div>
      </React.StrictMode>
    );
  }

  getPrevBadgeClass() {
    if (this.state.currentPage === 1) return 'page-item disabled';
    else return 'page-item';
  }

  getNextBadgeClass() {
    if (this.state.currentPage === MAX_PAGE) return 'page-item disabled';
    else return 'page-item';
  }

  getPrevButtonBadgeClass() {
    if (this.state.currentPage === 1) return 'page-link';
    else return 'page-link theme-button';
  }

  getNextButtonBadgeClass() {
    if (this.state.currentPage === MAX_PAGE) return 'page-link';
    else return 'page-link theme-button';
  }
}

export default TopDeals;
