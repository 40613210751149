import React, { useEffect, useReducer } from 'react';
import TopBar from '../components/navbar';
import Footer from '../components/footer';
import Container from '../components/container';
import SearchBar from '../components/searchbar';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
const TITLE = 'Search';
const queryString = require('query-string');
const search_link = 'https://sneakerplug-v1.herokuapp.com/api/search/?website=';

function get_api_url(website, state) {
  if (!state.query) return null;
  let url_query = '&search=' + state.query;
  let url_gender = '';
  let url_size = '';

  if (state.gender) url_gender = '&gender=' + state.gender;
  if (state.size) url_size = '&size=' + state.size;
  if (state.styleId) url_query = '&search=' + state.styleId;

  return search_link + website + url_query + url_gender + url_size;
}

const Search = props => {
  let location = useLocation();
  const initialState = {
    query: null,
    gender: null,
    brand: null,
    styleId: null,
    size: null,
  };

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, initialState);

  useEffect(() => {
    mountData();
  }, []);

  useEffect(() => {
    if (state.query) {
      // register only 1 view for each change
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [state]);

  function mountData() {
    const parsed = queryString.parse(props.location.search);
    let query = parsed.query;
    let gender = parsed.gender;
    let brand = parsed.brand;
    let styleId = parsed.styleId;
    let size = parseFloat(parsed.size);
    // input validation for URLs

    if (!query) query = null;
    if (!gender) gender = null;
    if (!brand) brand = null;
    if (!size || isNaN(size)) size = null;
    if (state.query !== query || state.gender !== gender || state.size !== size)
      setState({
        query: query,
        gender: gender,
        brand: brand,
        styleId: styleId,
        size: size,
      });
  }

  const handleSearch = (query, gender, size, brand, styleId) => {
    // console.log('Handling search');
    if (gender === 'Gender' || !gender) gender = null;
    if (size === 'Size' || !size) size = null;
    if (!brand) brand = null;
    if (!styleId) styleId = null;
    setState({
      query: query,
      gender: gender,
      brand: brand,
      styleId: styleId,
      size: size,
    });
  };
  // console.log('search size:' + this.state.size);
  return (
    <React.StrictMode>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TopBar />
      <div className="page-container">
        <SearchBar
          query={state.query}
          gender={state.gender}
          size={state.size}
          onSearch={handleSearch}
        />
        <p className="text-center text-secondary my-0">Showing results for</p>
        <h1 className="text-center font-weight-light mt-1 mb-4 mx-3">
          "{state.query}"
        </h1>
        <p
          hidden={state.styleId ? false : true}
          className="text-center text-secondary"
        >
          Style ID: <span className="heading">{state.styleId}</span>
        </p>

        <div className="container popular p-0 my-2">
          <Container
            website={'nike'}
            link={get_api_url('Nike', state)}
            size={state.size}
            hidden={
              state.brand === null ||
              state.brand.toLowerCase().includes('nike') ||
              state.brand.toLowerCase().includes('jordan')
                ? false
                : true
            }
          />
          <Container
            website={'stockx'}
            link={get_api_url('Stockx', state)}
            size={state.size}
          />
          <Container
            website={'goat'}
            link={get_api_url('Goat', state)}
            size={state.size}
          />
          <Container
            website={'stadiumgoods'}
            link={get_api_url('Stadiumgoods', state)}
            size={state.size}
          />
          <Container
            website={'flightclub'}
            link={get_api_url('Flightclub', state)}
            size={state.size}
          />
        </div>
      </div>
      <Footer />
    </React.StrictMode>
  );
};

export default Search;
