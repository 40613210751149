import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import ReactGA from 'react-ga';
const Filter = props => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    if (modal === false) {
      ReactGA.event({
        category: 'Filter',
        action: 'Filter Clicked',
      });
    }
    setModal(!modal);
  };

  const [priceRange, setPriceRange] = useState(2); // default range
  const [priceRangeText, setPriceRangeText] = useState('$50 - $100');
  const [priceRangeDropdown, setPriceRangeDropdownOpen] = useState(false);
  const togglePriceRangeDropdown = () => {
    if (priceRangeDropdown === false) {
      ReactGA.event({
        category: 'Filter',
        action: 'Price Range Toggle',
      });
      // console.log('Price Range toggle');
    }
    setPriceRangeDropdownOpen(prevState => !prevState);
  };

  const [gender, setGender] = useState('Men');
  const [genderDropdown, setGenderDropdownOpen] = useState(false);
  const toggleGenderDropdown = () => {
    if (genderDropdown === false) {
      ReactGA.event({
        category: 'Filter',
        action: 'Gender Toggle',
      });
      // console.log('Gender toggle');
    }
    setGenderDropdownOpen(prevState => !prevState);
  };

  const mensSizes = [
    'All',
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10,
    10.5,
    11,
    11.5,
    12,
    13,
    14,
    15,
    16,
  ];

  const brands = [
    'All',
    'Nike',
    'Jordan',
    'Adidas',
    'Under Armour',
    'New Balance',
    'Asics',
    'Puma',
    'Reebok',
    'Converse',
  ];
  const [size, setSize] = useState('All');
  const [sizeDropdown, setSizeDropdown] = useState(false);
  const toggleSizeDropdown = () => {
    if (sizeDropdown === false) {
      ReactGA.event({
        category: 'Filter',
        action: 'Size Toggle',
      });
      // console.log('Size toggle');
    }
    setSizeDropdown(!sizeDropdown);
  };

  const [brand, setBrand] = useState('All');
  const [brandDropdown, setBrandDropdown] = useState(false);
  const toggleBrandDropdown = () => {
    if (brandDropdown === false) {
      ReactGA.event({
        category: 'Filter',
        action: 'Brand Toggle',
      });
      // console.log('Brand toggle');
    }
    setBrandDropdown(!brandDropdown);
  };

  return (
    <div className="text-center text-md-left mt-2 mb-2">
      <Button className="filter-button" color="primary" onClick={toggleModal}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggleModal} className={className}>
        <ModalHeader toggle={toggleModal}>Filter results</ModalHeader>
        <ModalBody>
          <div className="row my-1 p-0">
            <div className="col-6 p-0">
              <p className="text-center my-1 my-sm-2">Price Range:</p>
            </div>
            <div className="col-6 p-0">
              <Dropdown
                isOpen={priceRangeDropdown}
                toggle={togglePriceRangeDropdown}
                className="filterContent"
              >
                <DropdownToggle caret>{priceRangeText}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Select Range:</DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setPriceRangeText('Less than $50');
                      setPriceRange(1);
                      ReactGA.event({
                        category: 'Filter',
                        action: 'Price Range Clicked',
                        label: 'Less than $50',
                      });
                    }}
                  >
                    Less than $50
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setPriceRangeText('$50 - $100');
                      setPriceRange(2);
                      ReactGA.event({
                        category: 'Filter',
                        action: 'Price Range Clicked',
                        label: '$50 - $100',
                      });
                    }}
                  >
                    $50 - $100
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setPriceRangeText('$100 - $150');
                      setPriceRange(3);
                      ReactGA.event({
                        category: 'Filter',
                        action: 'Price Range Clicked',
                        label: '$100 - $150',
                      });
                    }}
                  >
                    $100 - $150
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setPriceRangeText('Greater than $150');
                      setPriceRange(4);
                      ReactGA.event({
                        category: 'Filter',
                        action: 'Price Range Clicked',
                        label: 'Greater than $150',
                      });
                    }}
                  >
                    Greater than $150
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="row my-1 p-0">
            <div className="col-6 p-0">
              <p className="text-center my-1 my-sm-2">Gender:</p>
            </div>
            <div className="col-6 p-0">
              <Dropdown
                isOpen={genderDropdown}
                toggle={toggleGenderDropdown}
                className="filterContent"
              >
                <DropdownToggle caret>{gender}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Select Gender:</DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setGender('Men');
                      ReactGA.event({
                        category: 'Filter',
                        action: 'Gender Clicked',
                        label: 'Men',
                      });
                    }}
                  >
                    Men
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setGender('Women');
                      ReactGA.event({
                        category: 'Filter',
                        action: 'Gender Clicked',
                        label: 'Women',
                      });
                    }}
                  >
                    Women
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="row my-1 p-0">
            <div className="col-6 p-0">
              <p className="text-center my-1 my-sm-2">Size:</p>
            </div>
            <div className="col-6 p-0">
              <Dropdown
                isOpen={sizeDropdown}
                toggle={toggleSizeDropdown}
                className="filterContent"
              >
                <DropdownToggle caret>{size}</DropdownToggle>
                <DropdownMenu>
                  {mensSizes.map(showSize => (
                    <DropdownItem
                      key={showSize}
                      onClick={() => {
                        setSize(showSize);
                        ReactGA.event({
                          category: 'Filter',
                          action: 'Size Clicked',
                          label: showSize,
                        });
                      }}
                    >
                      {showSize}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="row my-1 p-0">
            <div className="col-6 p-0">
              <p className="text-center my-1 my-sm-2">Brand:</p>
            </div>
            <div className="col-6 p-0">
              <Dropdown
                isOpen={brandDropdown}
                toggle={toggleBrandDropdown}
                className="filterContent"
              >
                <DropdownToggle caret>{brand}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Select Brand:</DropdownItem>
                  {brands.map(showBrand => (
                    <DropdownItem
                      key={showBrand}
                      onClick={() => {
                        setBrand(showBrand);
                        ReactGA.event({
                          category: 'Filter',
                          action: 'Brand Clicked',
                          label: showBrand,
                        });
                      }}
                    >
                      {showBrand}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </ModalBody>
        <Button
          color="primary"
          onClick={() => {
            ReactGA.event({
              category: 'Filter',
              action: 'Filter Applied',
            });
            toggleModal();
            props.onFilter(priceRange, gender, size, brand);
          }}
          className="theme-button-filter"
        >
          Apply Filters
        </Button>
      </Modal>
    </div>
  );
};

export default Filter;
