import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TopBar from '../components/navbar';
import Footer from '../components/footer';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import '../App.css';
const TITLE = 'Privacy Policy';
const Policy = () => {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    // console.log(location.pathname + location.search);
  });
  return (
    <React.StrictMode>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TopBar />
      <div className="page-container">
        <p>
          <strong>Privacy Policy</strong>
        </p>
        <p>
          SneakerMobb.com respects the privacy of our users and has developed
          this Privacy Policy to demonstrate its commitment to protecting your
          privacy. This Privacy Policy is intended to describe for you, as an
          individual who is a user of SneakerMobb.com (and all websites and
          URL's controlled or operated by SneakerMobb.com which link to this
          policy, unless otherwise specified) or our services, or otherwise
          provide us with information through various means the information we
          collect, how that information may be used, with whom it may be shared,
          and your choices about such uses and disclosures.
          <br /> <br /> We are committed to protecting your personal information
          and your right to privacy. If you have any questions or concerns about
          our notice, or our practices with regards to your personal
          information, please contact us at contact.findyourkicks@gmail.com.
          <br /> <br /> We encourage you to read this Privacy Policy carefully
          when using our website or services or transacting business with us. By
          using our website, you are accepting the practices described in this
          Privacy Policy.
          <br /> <br /> If you have any questions about our privacy practices,
          please refer to the end of this Privacy Policy for information on how
          to contact us.
        </p>
        <p>
          <strong>
            <u>Information we collect about you</u>
          </strong>
          <br /> <br /> <u>In General</u>. We may collect non-personal
          information that does not identify you. When you provide personal
          information through our website, the information may be sent to
          servers located in the United States.
        </p>
        <ul>
          <li>
            <strong>Information you provide.</strong>
            We automatically collect certain anonymized information such as your
            IP address, browser and device characteristics, device type,
            country, location, information about when you use our Services and
            other technical information either directly or using Google
            Analytics. This information is primarily needed to maintain the
            security and operation of our Services, and for our internal
            analytics and reporting purposes. We use Google Analytics to track
            users counts and demographic information.
          </li>
        </ul>
        <p>
          <br />{' '}
          <u>Use of cookies and other technologies to collect information.</u>
          &nbsp;We use various technologies to collect information from your
          computer and about your activities on our site.
        </p>
        <ul>
          <li>
            <strong>Information collected automatically.</strong>We
            automatically collect information from your browser when you visit
            our website. This information includes your IP address, your browser
            type and language, access times, the content of any undeleted
            cookies that your browser previously accepted from us (see "Cookies"
            below), and the referring website address.
          </li>
          <li>
            <strong>Cookies.</strong>When you visit our website, we may assign
            your computer one or more cookies to facilitate access to our site
            and to personalize your online experience. Through the use of a
            cookie, we also may automatically collect information about your
            online activity on our site, such as the web pages you visit, the
            links you click, and the searches you conduct on our site. Most
            browsers automatically accept cookies, but you can usually modify
            your browser setting to decline cookies. If you choose to decline
            cookies, please note that you may not be able to sign in or use some
            of the interactive features offered on our website.
          </li>
          <li>
            <strong>Other Technologies.</strong>We may use standard Internet
            technology, such as Google Analytics, to track your use of our site.
          </li>
        </ul>
        <p>
          <strong>
            <u>How we use the information we collect</u>
          </strong>
          <br /> <br /> <u>In general.</u>&nbsp;We may use information that we
          collect about you to:
        </p>
        <ul>
          <li>deliver the products and services that you have requested;</li>
          <li>manage your account and provide you with customer support;</li>
          <li>
            perform research and analysis about your use of, or interest in, our
            products, services, or content, or products, services or content
            offered by others;
          </li>
          <li>
            communicate with you with regard to partially completed service
            requests;
          </li>
          <li>
            develop and display content and advertising tailored to your
            interests on our site and other sites;
          </li>
          <li>
            verify your eligibility and deliver prizes in connection with
            contests and sweepstakes;
          </li>
          <li>
            perform background screening, which may include the use of third
            parties, on Service Professionals
          </li>
          <li>enforce our terms and conditions;</li>
          <li>manage our business and</li>
          <li>
            perform functions as otherwise described to you at the time of
            collection.
          </li>
        </ul>
        <p>
          <strong>
            <u>With whom we share your information</u>
          </strong>
          <br /> <br /> We want you to understand when and with whom we may
          share personal or other information we have collected about you or
          your activities on our web site or while using our services.
          <br /> <br /> <u>Personal information.</u>&nbsp;We do not share your
          personal information with others except as indicated below or when we
          inform you and give you an opportunity to opt out of having your
          personal information shared. We may share personal information with:
        </p>
        <ul>
          <li>
            <strong>Authorized service providers: </strong>We may share your
            personal information with our authorized service providers that
            perform certain services on our behalf. These services may include
            fulfilling orders, processing credit card payments, delivering
            packages, providing customer service and marketing assistance,
            performing business and sales analysis, supporting our website
            functionality, and supporting contests, sweepstakes, surveys and
            other features offered through our website or performing background
            checks of Professionals. These service providers may have access to
            personal information needed to perform their functions but are not
            permitted to share or use such information for any other purposes.
          </li>
          <li>
            <strong>Business partners: </strong>When you make purchases,
            reservations or engage in promotions offered through our website or
            our services, we may share personal information with the businesses
            with which we partner to offer you those products, services,
            promotions, contests and/or sweepstakes. When you elect to engage in
            a particular merchant's offer or program, you authorize us to
            provide your email address and other information to that merchant.
          </li>
          <li>
            <strong>Other Situations. </strong>We also may disclose your
            information:
          </li>
          <li>
            In response to a subpoena or similar investigative demand, a court
            order, or a request for cooperation from a law enforcement or other
            government agency; to establish or exercise our legal rights; to
            defend against legal claims; or as otherwise required by law. In
            such cases, we may raise or waive any legal objection or right
            available to us.
          </li>
          <li>
            When we believe disclosure is appropriate in connection with efforts
            to investigate, prevent, or take other action regarding illegal
            activity, suspected fraud or other wrongdoing; to protect and defend
            the rights, property or safety of our company, our users, our
            employees, or others; to comply with applicable law or cooperate
            with law enforcement; or to enforce our website terms and conditions
            or other agreements or policies.
          </li>
          <li>
            In connection with a substantial corporate transaction, such as the
            sale of our business, a divestiture, merger, consolidation, or asset
            sale, or in the unlikely event of bankruptcy.
          </li>
        </ul>
        <p>
          Any third parties to whom we may disclose personal information may
          have their own privacy policies which describe how they use and
          disclose personal information. Those policies will govern use,
          handling and disclosure of your personal information once we have
          shared it with those third parties as described in this Privacy
          Policy. If you want to learn more about their privacy practices, we
          encourage you to visit the websites of those third parties. These
          entities or their servers may be located either inside or outside the
          United States.
        </p>
        <p>&nbsp;</p>
        <p>
          <u>Aggregated and non-personal information.</u>&nbsp;We may share
          aggregated and non-personal information we collect under any of the
          above circumstances. We may also share it with third parties to
          develop and deliver targeted advertising on our websites and on
          websites of third parties. We may combine non-personal information we
          collect with additional non-personal information collected from other
          sources. We also may share aggregated information with third parties,
          including advisors, advertisers and investors, for the purpose of
          conducting general business analysis. For example, we may tell our
          advertisers the number of visitors to our website and the most popular
          features or services accessed. This information does not contain any
          personal information and may be used to develop website content and
          services that we hope you and other users will find of interest and to
          target content and advertising.
          <br /> <br />{' '}
          <strong>
            <u>Third-party websites</u>
          </strong>
          <br /> <br /> There are a number of places on our website where you
          may click on a link to access other websites that do not operate under
          this Privacy Policy. For example, if you click on an advertisement or
          a search result on our website, you may be taken to a website that we
          do not control. These third-party websites may independently solicit
          and collect information, including personal information, from you and,
          in some instances, provide us with information about your activities
          on those websites. We recommend that you consult the privacy
          statements of all third-party websites you visit by clicking on the
          "privacy" link typically located at the bottom of the webpage you are
          visiting.
          <br /> <br />{' '}
          <strong>
            <u>How you can access your information</u>
          </strong>
          <br /> <br /> If you have an online consumer account with us, you have
          the ability to review and update your personal information online by
          logging into your account. You can also review and update your
          personal information by contacting us. More information about how to
          contact us is provided below.
          <br /> <br /> You can also choose to have your account disabled by
          contacting contact.findyourkicks@gmail.com. After you deactivate your
          account, you will not be able to sign in to our website or access any
          of your personal information. However, you can open a new account at
          any time. If you deactivate your account, we may still retain certain
          information associated with your account for analytical purposes and
          recordkeeping integrity, as well as to prevent fraud, collect any fees
          owed, enforce our terms and conditions, take actions we deem necessary
          to protect the integrity of our web site or our users, or take other
          actions otherwise permitted by law. In addition, if certain
          information has already been provided to third parties as described in
          this Privacy Policy, retention of that information will be subject to
          those third parties' policies.
          <br /> <br />{' '}
          <strong>
            <u>Your choices about collection and use of your information</u>
          </strong>
        </p>
        <ul>
          <li>
            You can choose not to provide us with certain information, but that
            may result in you being unable to use certain features of our
            website because such information may be required in order for you to
            register as a member of our directory service; to use our services;
            purchase products or services; participate in a contest, promotion,
            survey, or sweepstakes; ask a question; or initiate other
            transactions on our website.
          </li>
          <li>
            At any time, a consumer user can choose to no longer receive
            commercial or promotional emails or newsletters from us by accessing
            your user account and opting out. You also will be given the
            opportunity, in any commercial e-mail that we send to you, to opt
            out of receiving such messages in the future. It may take up to 30
            days for us to process an opt-out request. We may send you other
            types of transactional and relationship e-mail communications, such
            as service announcements, administrative notices, and surveys,
            without offering you the opportunity to opt out of receiving them.
          </li>
          <li>
            If you prefer not to have us share your postal mailing information
            with these selected providers of goods and services that may be of
            interest to you, you can notify us at any time by emailing us at{' '}
            <a href="mailto:contact.findyourkicks@gmail.com">
              contact.findyourkicks@gmail.com
            </a>
            .
          </li>
        </ul>
        <p>
          <strong>How we protect your personal information</strong>
          <br /> <br /> We take appropriate security measures (including
          physical, electronic and procedural measures) to help safeguard your
          personal information from unauthorized access and disclosure. For
          example, only authorized employees are permitted to access personal
          information, and they may do so only for permitted business functions.
          We use firewalls to help prevent unauthorized persons from gaining
          access to your personal information.
          <br /> <br /> We want you to feel confident using our website to
          transact business. However, no system can be completely secure.
          Therefore, although we take steps to secure your information, we do
          not promise, and you should not expect, that your personal
          information, searches, or other communications will always remain
          secure. Please refer to the Federal Trade Commission's website
          at&nbsp;
          <a href="http://www.ftc.gov/bcp/menus/consumer/data.shtm">
            http://www.ftc.gov/bcp/menus/consumer/data.shtm
          </a>
          &nbsp;for information about how to protect yourself against identity
          theft.
          <br /> <br />{' '}
          <strong>Blogs, bulletin boards, reviews and chat rooms</strong>
          <br /> <br /> We may provide areas on our websites where you can post
          information about yourself and others and communicate with others, as
          well as post reviews of products, establishments, contractors, and the
          like, or upload content (e.g. pictures, videos, audio files, etc.).
          Such postings are governed by our Terms &amp; Conditions. &nbsp;In
          addition, such postings may appear on other websites or when searches
          are executed on the subject of your posting. Also, whenever you
          voluntarily disclose personal information on publicly viewable web
          pages, that information will be publicly available and can be
          collected and used by others. For example, if you post your email
          address, you may receive unsolicited messages. We cannot control who
          reads your posting or what other users may do with the information you
          voluntarily post, so we encourage you to exercise discretion and
          caution with respect to your personal information. Once you have
          posted information, you may not be able to edit or delete such
          information.
          <br /> <br /> <strong>Children's privacy</strong>
          <br /> <br /> Our website is a general audience site, and we do not
          knowingly collect personal information from children under the age of
          13.
          <br /> <br />{' '}
          <strong>Visiting our websites from outside the United States</strong>
          <br /> <br /> This Privacy Policy is intended to cover collection of
          information on our website from residents of the United States. If you
          are visiting our website from outside the United States, please be
          aware that your information may be transferred to, stored, and
          processed in the United States where our servers are located, and our
          central database is operated. The data protection and other laws of
          the United States and other countries might not be as comprehensive as
          those in your country. Please be assured that we seek to take
          reasonable steps to ensure that your privacy is protected. By using
          our services, you understand that your information may be transferred
          to our facilities and those third parties with whom we share it as
          described in this privacy policy.
          <br /> <br /> <strong>No Rights of Third Parties</strong>
          <br /> <br /> This Privacy Policy does not create rights enforceable
          by third parties or require disclosure of any personal information
          relating to users of the website.
          <br /> <br /> <strong>Changes to this Privacy Policy</strong>
          <br /> <br /> We will occasionally update this Privacy Policy to
          reflect changes in our practices and services. We recommend that you
          check our website from time to time to inform yourself of any changes
          in this Privacy Policy or any of our other policies.
        </p>
        <p>
          <strong>
            <u>How to contact us</u>
          </strong>
          <br /> <br /> If you have any questions about this Privacy Policy or
          our information-handling practices, or if you would like to request
          information about our disclosure of personal information to third
          parties for their direct marketing purposes, please contact us by
          e-mail as follows: contact.findyourkicks@gmail.com
        </p>
        <p>
          For our Canadian users:
          <br /> <br /> Your rights to access your personal information are not
          absolute. We may deny access:
        </p>
        <ul>
          <li>When denial of access is required by law</li>
          <li>
            When granting you access would have an unreasonable impact on other
            people's privacy;
          </li>
          <li>To protect our Company's rights and property; or</li>
          <li>Where the request if frivolous or vexatious.</li>
        </ul>
        <p>
          <br /> If we deny your request for access to, or refuse a request to
          correct personal information, we will explain why.
          <br /> <br /> SneakerMobb.com,&nbsp;All rights reserved.
        </p>
        <p>
          We may provide this information in a standardized format that is not
          specific to you. The designated email address for these requests is
          Contact.FindYourKicks@artistdirectory.com.
        </p>
        <p>
          <strong>
            <u>Policy Changes</u>
          </strong>
        </p>
        <p>
          We reserve the right to change this privacy policy at any time to
          reflect the changing needs of the company. We will do our best to
          communicate changes to all registered users but cannot guarantee
          notice of changes. Any Changes will be posted&nbsp; here.
        </p>
      </div>
      <Footer />
    </React.StrictMode>
  );
};

export default Policy;
