import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import ReactGA from 'react-ga';
const Info = () => {
  return (
    <React.Fragment>
      <div className="container border">
        <div className="row my-3">
          <div className="col-md">
            <div className="text-center">
              <img
                src={require('../assets/img/newspaper.svg')}
                alt=""
                width="28"
                height="28"
                title="Info"
                className="mr-2 mb-2"
              />
              <span className="font-weight-light theme-link">
                Compare Prices
              </span>
            </div>
            <p className="text-center mx-sm-5">
              Find the best prices and check size availabilty across all major
              retailers and online stores with ease.
            </p>
            <hr className="d-block d-md-none" />
          </div>

          <div className="col-md">
            <div className="text-center">
              <img
                src={require('../assets/img/hourglass-split.svg')}
                alt=""
                width="28"
                height="28"
                title="Info"
                className="mr-2 mb-2"
              />
              <Link to="/topdeals" className="theme-link">
                <span className="font-weight-light">Search Deals</span>
              </Link>
            </div>
            <p className="text-center mx-sm-5">
              Browse through the best deals on shoes from all retailers curated
              from one of the biggest databases for shoes.
            </p>
            <div className="text-center">
              <Link to="/topdeals">
                <Button
                  onClick={() => {
                    ReactGA.event({
                      category: 'MainPage',
                      action: 'TopDeals Button Clicked',
                    });
                  }}
                  className="d-md-none theme-button"
                >
                  View Deals
                </Button>
              </Link>
            </div>
          </div>

          {/* <div className="col-md">
            <div className="text-center">
              <img
                src={require('../assets/img/arrow-up-left-circle.svg')}
                alt=""
                width="28"
                height="28"
                title="Top Deals"
                className="mr-2 mb-2"
              />
              <Link to="/popular" className="theme-link">
                <span className="font-weight-light">Most Popular</span>
              </Link>
            </div>

            <p className="text-center">
              Find the most sought after sneakers fellow users have bought from
              all the stores
            </p>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Info;
