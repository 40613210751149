import React, { useState } from 'react';
import findIndex from 'lodash/findIndex';
import ReactGA from 'react-ga';
function log_click(id) {
  // console.log(id);
  ReactGA.event({
    category: 'Product',
    action: 'Shoe Clicked',
    label: id,
  });
  fetch('https://sneakerplug-v1.herokuapp.com/api/popular/' + id, {
    method: 'put',
  });
  // .then(res => res.text()) // DEBUGGING
  // .then(res => console.log(res)) // DEBUGGING
  // .catch(err => console.log(err));
}
const Box = ({ size, shoe, loading }) => {
  if (loading)
    return (
      <div className="col-6 col-md-3">
        <div className="spinner-border mx-5 my-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  let og_price_flare = false;
  let display_og_price;
  let price_flare = 'card-text';
  let lowest_current_price = shoe.lowest_current_price;
  let imageType = 'card-img-top';
  if (shoe.website === 'Flightclub') imageType += ' pt-2 pb-5 px-1';
  else if (shoe.website === 'Stockx') imageType += ' mt-4 mb-1 pt-4 pb-4 px-3';
  else if (shoe.website === 'Stadiumgoods') imageType += ' mt-4 mb-3 pt-4 pb-4';
  // console.log('box size:' + size);
  if (size) {
    let index = findIndex(shoe.sizes, ['size', size]);
    // sometimes even if size is specified in query, the data returned may not be have the size, in that case it will output whatever is the lowest price
    // if (!shoe.sizes[index])
    //   console.log('website' + shoe.website + 'title' + shoe.title);

    // check why this is happening
    // if (index < 0)
    //   console.log(
    //     'investigate:' +
    //       'website:' +
    //       shoe.website +
    //       'title' +
    //       shoe.title +
    //       'size' +
    //       shoe.sizes
    //   );
    if (index > 0 && shoe.sizes[index] && shoe.sizes[index].price !== null)
      lowest_current_price = shoe.sizes[index].price;
  }
  if (shoe.og_price != null && lowest_current_price < shoe.og_price) {
    og_price_flare = true;
    display_og_price = '$' + shoe.og_price;
    price_flare += ' text-danger';
  }

  const [imageLoading, setImageLoading] = useState(true);
  const imageLoaded = () => {
    setImageLoading(false);
  };
  // if (!shoe.image) setImageLoading(false);
  return (
    <React.Fragment>
      <div className="col-6 col-md-3 box">
        <div>
          <a
            key={shoe.link}
            href={shoe.link}
            target="blank"
            className="dark product"
            onClick={() => {
              log_click(shoe._id);
            }}
          >
            <div className="product-body">
              <div
                style={{ display: imageLoading ? 'block' : 'none' }}
                className="spinner-border mx-auto my-5"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
              <img
                className={imageType}
                src={shoe.image}
                alt="Cannot be displayed"
                style={{ display: imageLoading ? 'none' : 'block' }}
                onLoad={imageLoaded}
              ></img>
              <div className="card-body px-0">
                <p className="card-text font-weight-light text-muted">
                  {shoe.website}
                </p>
                <p className="box-title card-title font-weight-normal">
                  {shoe.title}
                </p>
                <p className="card-text font-weight-bold">
                  <span className="card-text price-box">
                    <span hidden={!og_price_flare}>
                      <s>{display_og_price}</s>&nbsp;&nbsp;
                    </span>
                    <span className={price_flare}>${lowest_current_price}</span>
                  </span>
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Box;
