import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TopBar from '../components/navbar';
import Footer from '../components/footer';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import '../App.css';
const TITLE = 'Mobb-Bot';
const Bot = () => {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    // console.log(location.pathname + location.search);
  });
  return (
    <React.StrictMode>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TopBar />
      <div className="page-container">
        <h1 className="text-center font-weight-normal my-5 mx-4">
          SneakerMobb Bot
        </h1>
        <div className="container">
          <div className="mb-5">
            <h4 className="text-left font-weight-normal ml-2">
              Overview of the API bot
            </h4>
            <p className="text-left font-weight-light ml-2">
              Our users are searching for shoes availability and the Mobb-Bot
              (generic name for SneakerMobb’s bot) uses multiple website’s API’s
              on an as needed basis to grab the data to display on our webpages.
              All the traffic to our webpages is redirected to yours to help
              users find their shoes and purchase them.{' '}
            </p>
          </div>

          <div className="mb-5">
            <h4 className="text-left font-weight-normal ml-2">
              How your data is used
            </h4>
            <p className="text-left font-weight-light ml-2">
              Data may be temporarily stored to avoid repeated requests for same
              resources in order to be considerate to your servers. In most
              cases, Mobb-Bot shouldn’t send requests to your API more than once
              every few seconds on average. We only have one backend server
              running, so all Mobb-Bot requests should be coming from the same
              machine.
            </p>
            <p className="text-left font-weight-light ml-2">
              The user-agent string is as follows:{' '}
              <span className="heading">
                Mozilla/5.0 AppleWebKit/537.36
                (KHTML, like Gecko; compatible; Mobb-Bot/1.1;
                +https://www.sneakermobb.com/#/bot.html)
              </span>
            </p>
            <p className="text-left font-weight-light ml-2">
              The number of requests to your API depends on the number of users
              visited. Please reach out to us if you would like to request a
              change in requests rate if you are seeing server-load problems
              that are definitely only caused by Mobb-bot.
            </p>
            <p className="text-left font-weight-light ml-2">
              Contact Us:{' '}
              <a href="mailto:contact.findyourkicks@gmail.com">
                contact.findyourkicks@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </React.StrictMode>
  );
};

export default Bot;
