import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TopBar from '../components/navbar';
import SearchBar from '../components/searchbar';
import Footer from '../components/footer';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import '../App.css';
const TITLE = 'About';
const About = () => {
  let location = useLocation();
  const handleSearch = (query, gender, size, brand, styleId) => {
    // console.log('About: Handling search');
  };
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    // console.log(location.pathname + location.search);
  });
  return (
    <React.StrictMode>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TopBar />
      <div className="page-container">
        <h1 className="text-center font-weight-normal my-5 mx-4">About</h1>
        <div className="container">
          <div className="mb-5">
            <h4 className="text-left font-weight-normal ml-2">
              Search engine for shoes
            </h4>
            <p className="text-left font-weight-light ml-2">
              Need to <span className="heading">find the best prices</span> for
              shoes from different outlets? Want to{' '}
              <span className="heading">check sizes and availability </span>{' '}
              with ease? SneakerMobb checks different retailers to present you
              all the best options for you to compare. We aren’t taking any
              payments, all your purchases will be made from one of the retailer
              sites directly.
            </p>
          </div>

          <div className="">
            <h4 className="text-left font-weight-normal ml-2">Browse</h4>
            <p className="text-left font-weight-light ml-2">
              Don’t know what you’re looking for? You can check out the{' '}
              <span className="heading">best deals</span> from all the retailers
              for your size in the top deals section. You can also find the{' '}
              <span className="heading">most popular</span> shoes in the popular
              section.
            </p>
            <div className="text-center">
              <Link to="/topdeals">
                <Button
                  onClick={() => {
                    ReactGA.event({
                      category: 'MainPage',
                      action: 'TopDeals Button Clicked',
                    });
                  }}
                  className="theme-button"
                >
                  View Deals
                </Button>
              </Link>
            </div>
          </div>
          <hr />
        </div>
        <SearchBar aboutPage={true} onSearch={handleSearch} />
      </div>
      <Footer />
    </React.StrictMode>
  );
};

export default About;
