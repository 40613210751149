import React, { useEffect } from 'react';
import Footer from '../components/footer';
import Info from '../components/info';
import TopBar from '../components/navbar';
import SearchBar from '../components/searchbar';
import Popular from '../components/popular';
import '../App.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
const TITLE = 'Sneaker Mobb';

const MainPage = () => {
  let location = useLocation();
  const handleSearch = (query, gender, size, brand, styleId) => {
    // do nothing?
    // console.log("handling search");
  };
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    // console.log(location.pathname + location.search);
  });
  return (
    <React.StrictMode>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TopBar />
      <div className="page-container">
        <div className="content-wrap">
          <div className="my-5 mx-4">
            <h1 className="text-center font-weight-light">
              Find your kicks for the{' '}
              <span className="heading">best prices</span> on the internet.
            </h1>
            <h6 className="text-center font-weight-light">
              <Link className="dark" to="/about">
                Learn more
              </Link>
            </h6>
          </div>

          <SearchBar onSearch={handleSearch} />
          <Info />
          <Popular />
        </div>
      </div>

      <Footer />
    </React.StrictMode>
  );
};

export default MainPage;
