import React, { useState } from 'react';
import {
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import Autocomplete from './Autocomplete';
import ReactGA from 'react-ga';
const searchClient = algoliasearch(
  'N184ZFU327',
  '831524f2c9c9308c8cc624e2ef8f7960'
);
function update_url(query, gender, size, brand, styleId) {
  if (!query) return null;
  let url_gender = '';
  let url_size = '';
  let url_brand = '';
  let url_styleId = '';
  if (gender !== 'Gender' && gender) url_gender = '&gender=' + gender;
  if (size !== 'Size' && size) url_size = '&size=' + size;
  if (brand) url_brand = '&brand=' + brand;
  if (styleId) url_styleId = '&styleId=' + styleId;

  return (
    '/search/?query=' + query + url_gender + url_brand + url_size + url_styleId
  );
}
const SearchBar = props => {
  // console.log(
  //   'Query:' + props.query + 'Gender:' + props.gender + 'Size:' + props.size
  // );
  // let input_query = props.query;
  // let input_gender = props.gender;
  // let input_size = props.size;
  // console.log(
  //   'Query:' + input_query + 'Gender:' + input_gender + 'Size:' + input_size
  // );

  // if (!input_gender || (input_gender !== 'Men' && input_gender !== 'Women'))
  //   input_gender = 'Gender';

  // console.log(input_gender);
  const [gender, setGender] = useState('Gender');
  const [genderDropdownOpen, setGenderDropdownOpen] = useState(false);
  const genderDropDown = () => {
    if (genderDropdownOpen === false) {
      ReactGA.event({
        category: 'SearchBar',
        action: 'Gender Toggle',
      });
    }
    setGenderDropdownOpen(!genderDropdownOpen);
  };

  const mensSizes = [
    '-',
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10,
    10.5,
    11,
    11.5,
    12,
    13,
    14,
    15,
    16,
  ];

  const [size, setSize] = useState('Size');
  const [sizeDropdownOpen, setDropdownOpen] = useState(false);
  const sizeDropDown = () => {
    if (sizeDropdownOpen === false) {
      ReactGA.event({
        category: 'SearchBar',
        action: 'Size Toggle',
      });
    }
    setDropdownOpen(!sizeDropdownOpen);
  };

  const [brand, setBrand] = useState('');
  const [query, setQuery] = useState('');
  const [styleId, setStyleId] = useState('');

  const [valid, setValid] = useState(true);
  const onInvalid = () => setValid(false);
  const onValid = () => setValid(true);

  const onSuggestionSelected = (_, { suggestion }) => {
    setQuery(suggestion.query);
    setBrand(suggestion.brand);
    setStyleId(suggestion.styleId);
  };

  const onSuggestionCleared = () => {
    // setQuery('');
  };

  return (
    <div className="container my-5">
      <p
        hidden={!props.aboutPage}
        className="text-left font-weight-light ml-3 ml-lg-4 pl-lg-2 ml-xl-5 pl-xl-4"
      >
        <span className="heading">Start searching now:</span>
      </p>
      <div className="row px-lg-5">
        <div className="col-12 col-md-6 col-lg-8 px-0 px-md-1 px-xl-5">
          <InstantSearch
            indexName="sneakerplug_queries"
            searchClient={searchClient}
          >
            <Configure hitsPerPage={10} />
            <Autocomplete
              onSuggestionSelected={onSuggestionSelected}
              onSuggestionCleared={onSuggestionCleared}
            />
          </InstantSearch>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0 px-0">
          <div className="button-bar container px-0">
            <InputGroup>
              <InputGroupButtonDropdown
                addonType="prepend"
                isOpen={genderDropdownOpen}
                toggle={genderDropDown}
              >
                <DropdownToggle className="genderButton" caret>
                  {gender}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      ReactGA.event({
                        category: 'SearchBar',
                        action: 'Gender Clicked',
                        label: 'All',
                      });
                      setGender('All');
                    }}
                  >
                    -
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      ReactGA.event({
                        category: 'SearchBar',
                        action: 'Gender Clicked',
                        label: 'Men',
                      });
                      setGender('Men');
                    }}
                  >
                    Men
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      ReactGA.event({
                        category: 'SearchBar',
                        action: 'Gender Clicked',
                        label: 'Women',
                      });
                      setGender('Women');
                    }}
                  >
                    Women
                  </DropdownItem>
                </DropdownMenu>
              </InputGroupButtonDropdown>
              <InputGroupButtonDropdown
                addonType="append"
                isOpen={sizeDropdownOpen}
                toggle={sizeDropDown}
              >
                <DropdownToggle className="sizeButton btn-block" caret>
                  {size}
                </DropdownToggle>
                <DropdownMenu>
                  {mensSizes.map(showSize => (
                    <DropdownItem
                      key={showSize}
                      onClick={() => {
                        if (showSize === '-') {
                          ReactGA.event({
                            category: 'SearchBar',
                            action: 'Size Clicked',
                            label: 'All',
                          });
                          setSize('Size');
                        } else {
                          ReactGA.event({
                            category: 'SearchBar',
                            action: 'Size Clicked',
                            label: showSize,
                          });
                          setSize(showSize);
                        }
                      }}
                    >
                      {showSize}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </InputGroupButtonDropdown>
              <InputGroupAddon addonType="append">
                <Link
                  to={() => {
                    let new_url = update_url(
                      query,
                      gender,
                      size,
                      brand,
                      styleId
                    );
                    if (new_url !== null) return new_url;
                  }}
                >
                  <Button
                    onClick={() => {
                      if (!query) {
                        ReactGA.event({
                          category: 'SearchBar',
                          action: 'Search Clicked',
                          label: 'Invalid',
                        });
                        onInvalid();
                      } else {
                        ReactGA.event({
                          category: 'SearchBar',
                          action: 'Search Clicked',
                          label: 'Valid',
                        });
                        onValid();
                        props.onSearch(query, gender, size, brand, styleId);
                      }
                    }}
                    color="primary"
                    className="search-button"
                  >
                    Search
                  </Button>
                </Link>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </div>
      </div>
      <h6
        hidden={valid}
        className="container text-center font-weight-normal mt-3"
      >
        <div className="alert alert-danger" role="alert">
          Please enter a shoe you're looking for!
        </div>
      </h6>
    </div>
  );
};

export default SearchBar;
