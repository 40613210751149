import React, { Component } from 'react';
import Box from './box';
import ReactGA from 'react-ga';
import { ArrowLeftIcon, ArrowRightIcon } from '@primer/octicons-react';
const MAX_SHOES_PER_PAGE = 4;
class Container extends Component {
  state = {
    website: this.props.website,
    // size: this.props.size,
    loading: true,
    currentPage: 1,
    shoesPerPage: MAX_SHOES_PER_PAGE,
    shoes: [],
    currentShoes: [],
  };

  componentDidMount() {
    // console.log('Container - Mounted');
    // console.log(this.state.website + 'container fetching');
    if (this.props.link !== null)
      fetch(this.props.link)
        .then(res => res.json())
        .then(
          result => {
            this.setState({
              loading: false,
              shoes: result,
            });
            this.setPage(this.state.currentPage);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          error => {
            this.setState({
              loading: false,
              shoes: [],
            });
            this.setPage(this.state.currentPage);
          }
        );
    else
      this.setState({
        loading: true,
        shoes: [],
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.link !== this.props.link) {
      // console.log('updated and load set');
      this.setState({ loading: true });
      this.componentDidMount();
    }
  }

  prevPage = () => {
    if (this.state.currentPage > 1) {
      ReactGA.event({
        category: 'Supplementary Buttons',
        action: 'Prev Clicked',
        label: this.state.website,
      });
      this.setState({ currentPage: this.state.currentPage - 1 });
      this.setPage(this.state.currentPage - 1);
    }
  };

  nextPage = () => {
    if (
      this.state.currentPage <
      Math.ceil(this.state.shoes.length / this.state.shoesPerPage)
    ) {
      ReactGA.event({
        category: 'Supplementary Buttons',
        action: 'Next Clicked',
        label: this.state.website,
      });
      this.setState({ currentPage: this.state.currentPage + 1 });
      this.setPage(this.state.currentPage + 1);
    }
  };

  setPage = pageNumber => {
    const indexOfLastShoe = pageNumber * this.state.shoesPerPage;
    const indexOfFirstShoe = indexOfLastShoe - this.state.shoesPerPage;
    this.setState({
      currentShoes: this.state.shoes.slice(indexOfFirstShoe, indexOfLastShoe),
    });
  };

  render() {
    if (this.state.loading === true) {
      return (
        <React.StrictMode>
          <div hidden={this.props.hidden} className="card mb-5">
            <h5 className="card-header py-1">
              <a target="blank" href={'https://' + this.state.website + '.com'}>
                <img
                  src={require('../assets/img/' + this.state.website + '.png')}
                  alt={this.state.website}
                  title="Info"
                  height="35px"
                />
              </a>
              <p className="results text-center text-secondary font-weight-light mt-1 mb-0">
                (Finding results...)
              </p>
            </h5>
            <div className="container">
              <div className="d-flex justify-content-center my-5">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <ul
              className="pagination justify-content-center"
              style={{ margin: '0.2rem' }}
            >
              <li className={this.getPrevBadgeClass()}>
                <button
                  onClick={this.prevPage}
                  className={this.getPrevButtonBadgeClass()}
                >
                  <ArrowLeftIcon size={16} />
                  Prev
                </button>
              </li>
              <li className={this.getNextBadgeClass()}>
                <button
                  onClick={this.nextPage}
                  className={this.getNextButtonBadgeClass()}
                >
                  Next
                  <ArrowRightIcon size={16} />
                </button>
              </li>
            </ul>
          </div>
        </React.StrictMode>
      );
    }

    let html_rows = [];
    for (let i = 0; i < this.state.currentShoes.length; i++) {
      html_rows.push(
        <Box
          size={this.props.size}
          loading={this.state.loading}
          shoe={this.state.currentShoes[i]}
          key={this.state.currentShoes[i].id}
        />
      );
    }
    let num_results = this.state.shoes.length;
    if (!num_results) num_results = 0;
    let supplier_link =
      this.state.website.toLowerCase().replace(' ', '') + '.com';
    if (html_rows.length === 0)
      html_rows.push(
        <div className="alert alert-warning text-center" role="alert">
          No shoes found. Try visiting{' '}
          <a href={'https://www.' + supplier_link} className="alert-link">
            {supplier_link}
          </a>{' '}
        </div>
      );
    else html_rows = <div className="row row-box p-0">{html_rows}</div>;

    return (
      <React.StrictMode>
        <div hidden={this.props.hidden} className="card mb-5">
          <h5 className="card-header py-1">
            <a target="blank" href={'https://' + this.state.website + '.com'}>
              <img
                src={require('../assets/img/' + this.state.website + '.png')}
                alt={this.state.website}
                title="Info"
                height="35px"
              />
            </a>
            <p className="results text-center text-secondary font-weight-light mt-1 mb-0">
              (Found <span className="heading">{num_results}</span> results)
            </p>
          </h5>
          <div className="container">{html_rows}</div>
          <ul
            className="pagination justify-content-center"
            style={{ margin: '0.2rem' }}
          >
            <li className={this.getPrevBadgeClass()}>
              <button
                onClick={this.prevPage}
                className={this.getPrevButtonBadgeClass()}
              >
                <ArrowLeftIcon size={16} />
                Prev
              </button>
            </li>
            <li className={this.getNextBadgeClass()}>
              <button
                onClick={this.nextPage}
                className={this.getNextButtonBadgeClass()}
              >
                Next
                <ArrowRightIcon size={16} />
              </button>
            </li>
          </ul>
        </div>
      </React.StrictMode>
    );
  }

  getPrevBadgeClass() {
    if (this.state.currentPage === 1) return 'page-item disabled';
    else return 'page-item';
  }

  getNextBadgeClass() {
    if (
      this.state.currentPage ===
      Math.ceil(this.state.shoes.length / this.state.shoesPerPage)
    )
      return 'page-item disabled';
    else return 'page-item';
  }

  getPrevButtonBadgeClass() {
    if (this.state.currentPage === 1) return 'page-link';
    else return 'page-link theme-button';
  }

  getNextButtonBadgeClass() {
    if (
      this.state.currentPage ===
      Math.ceil(this.state.shoes.length / this.state.shoesPerPage)
    )
      return 'page-link';
    else return 'page-link theme-button';
  }
}

export default Container;
