import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Highlight, connectAutoComplete } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';

class AutoComplete extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentRefinement: PropTypes.string.isRequired,
    refine: PropTypes.func.isRequired,
    onSuggestionSelected: PropTypes.func.isRequired,
    onSuggestionCleared: PropTypes.func.isRequired,
  };

  state = {
    value: this.props.currentRefinement,
  };

  onChange = (_, { newValue }) => {
    if (!newValue) {
      this.props.onSuggestionCleared();
    }

    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value);
  };

  onSuggestionsClearRequested = () => {
    this.props.refine();
  };

  getSuggestionValue(hit) {
    return hit.query;
  }

  // suggestions are rendered here
  renderSuggestion(hit) {
    if (hit.image !== null && hit.image !== undefined) {
      let table = (
        <table>
          <tbody>
            <tr>
              <td hidden={!hit.image}>
                <img alt="" src={hit.image} style={{ width: '100px' }}></img>
              </td>
              <td>
                <Highlight attribute="query" hit={hit} tagName="mark" />
                <br />
                <Highlight attribute="styleId" hit={hit} tagName="mark" />
              </td>
            </tr>
          </tbody>
        </table>
      );
      return table;
    } else
      return (
        <table>
          <tbody>
            <tr>
              <td>
                <Highlight attribute="query" hit={hit} tagName="mark" />
                <br />
                <Highlight attribute="styleId" hit={hit} tagName="mark" />
              </td>
            </tr>
          </tbody>
        </table>
      );
  }

  render() {
    const { hits, onSuggestionSelected } = this.props;
    const { value } = this.state;

    const inputProps = {
      placeholder: 'Ex: "Nike React Presto"',
      onChange: this.onChange,
      value,
    };

    return (
      <AutoSuggest
        suggestions={hits}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

export default connectAutoComplete(AutoComplete);
