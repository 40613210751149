import React, { useEffect } from 'react';
import TopBar from '../components/navbar';
import Footer from '../components/footer';
import Popular from '../components/popular';
import '../App.css';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
const TITLE = 'Popular';
const PopularPage = () => {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    // console.log(location.pathname + location.search);
  });
  return (
    <React.StrictMode>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TopBar />
      <div className="page-container">
        <Popular />
      </div>

      <Footer />
    </React.StrictMode>
  );
};

export default PopularPage;
