import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TopBar from '../components/navbar';
import SearchBar from '../components/searchbar';
import Footer from '../components/footer';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import '../App.css';
const TITLE = 'New Drops';
const NewDrops = () => {
  let location = useLocation();
  const handleSearch = (query, gender, size, brand, styleId) => {
    // console.log('About: Handling search');
  };
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    // console.log(location.pathname + location.search);
  });
  return (
    <React.StrictMode>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TopBar />
      <div className="page-container">
        <h1 className="text-center font-weight-normal my-5 mx-4">New Drops</h1>
        <div className="container">
          <div className="mb-5">
            <h4 className="text-center font-weight-light">
              Coming Soon{' '}
              <span role="img" aria-label="emoji">
                🤫
              </span>
            </h4>
            {/* <p className="text-left font-weight-light ml-2">
              Need to find the best price for a shoe from different outlets?
              Want to check sizes and availability with ease? SneakerMobb checks
              different retailers to present you all the best options for you to
              compare. We aren’t taking any payments, all your purchases will be
              made from one of the retailer sites directly.
            </p> */}
          </div>
        </div>

        <SearchBar aboutPage={true} onSearch={handleSearch} />
      </div>
      <Footer />
    </React.StrictMode>
  );
};

export default NewDrops;
