import React from 'react';
import '../Footer.css';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="row">
          <Link to="/" className="navbar-brand">
            <img
              src={require('./sm_new_white.png')}
              style={{ width: '200px' }}
              alt="Sneaker Plug"
              className="img-fluid"
            />
          </Link>

          <span className="ml-auto mr-0 my-auto">
            <Link to="/privacy" className="policy">
              Privacy Policy
            </Link>
          </span>
        </div>
        <span className="mx-auto mt-3 text-center d-block ">
          A divison of Business Axis Inc. &copy; 2020
        </span>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
