import React, { useEffect } from 'react';
import './App.css';
import {
  HashRouter,
  // BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

// Pages
import MainPage from './pages/main';
import Search from './pages/search';
import PopularPage from './pages/popular';
import TopDealsPage from './pages/topdeals';
import NewDrops from './pages/newdrops';
import About from './pages/about';
import Policy from './pages/policy';
import Bot from './pages/bot';
import NotFoundPage from './pages/404';

// google analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-177213501-1');
// import Test from './pages/test';
function App() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://s.skimresources.com/js/169864X1637759.skimlinks.js';
    script.type = 'text/javascript';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <HashRouter basename="/">
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exact path="/popular" component={PopularPage} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/topdeals" component={TopDealsPage} />
        <Route exact path="/newdrops" component={NewDrops} />
        <Route exact path="/about" component={About} />
        <Route exact path="/privacy" component={Policy} />
        <Route exact path="/bot.html" component={Bot} />
        {/* <Route exact path="/test" component={Test} /> */}
        <Route exact path="/404" component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
    </HashRouter>
  );
}

export default App;
